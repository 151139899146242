import { FunctionComponent } from "react"
import { useMarginReportData } from "./useMarginReportData"
import styles from "../../index.module.css"
import { MarginReportStaffQuery } from "./useMarginReportData.generated"
import { getMarginCellColor } from "./utils/getMarginCellColor"

const getMarginTableRows = (marginReport: MarginReportStaffQuery["marginReport"]) => {
  const consultants = [...marginReport.rows]
  consultants.sort((a, b) => {
    if (a.annualMargin > b.annualMargin) {
      return 1
    }
    return -1
  })
  return consultants.map((row) => (
    <tr key={row.staffMemberId}>
      <td>{row.name}</td>
      <td>{row.seniority}</td>
      <td>${row.annualRevenue}</td>
      <td>${row.annualCost}</td>
      <td
        className={styles.rightAlign}
        style={{ backgroundColor: getMarginCellColor(row.annualMargin, marginReport.outlierScope) }}
      >
        {row.annualMargin}%
      </td>
    </tr>
  ))
}

export const MarginReport: FunctionComponent = () => {
  const { loading, data, error } = useMarginReportData()

  if (loading) return <h2>Loading...</h2>
  if (error) return <h2>Margin Report not found</h2>
  if (!data?.marginReport) return <h2>No Margin report exists</h2>
  return (
    <>
      <h1>Annual Margin Report</h1>
      <table className={styles.table}>
        <thead>
          <tr>
            <th className={styles.th}>Name</th>
            <th className={styles.th}>Level</th>
            <th className={styles.th}>Revenue</th>
            <th className={styles.th}>Cost</th>
            <th className={styles.th}>Margin</th>
          </tr>
        </thead>
        <tbody>{getMarginTableRows(data.marginReport)}</tbody>
      </table>
    </>
  )
}
