import { FunctionComponent } from "react"
import { useStaffData } from "./useStaffData"
import { Link } from "react-router-dom"
import { StaffQuery } from "./useStaffData.generated"
import styles from "../../index.module.css"

const getConsultantTableRows = (consultants: StaffQuery["staff"]) => {
  const consultantsCopy = [...consultants]
  consultantsCopy.sort((a, b) => {
    if (a.contract.level.rate < b.contract.level.rate) {
      return 1
    }
    return -1
  })
  return consultantsCopy.map((consultant) => (
    <tr key={consultant.name}>
      <td role="cell">
        <Link to={`/consultant-profile/${consultant.id}`}>{consultant.name}</Link>
      </td>
      <td role="cell">{consultant.contract?.level?.seniority}</td>
    </tr>
  ))
}

export const Consultants: FunctionComponent = () => {
  const { loading, data, error } = useStaffData()

  if (loading) return <h2>Loading...</h2>
  if (error) return <h2>No staff found</h2>
  if (!data?.staff) return <h2>No staff exist</h2>
  return (
    <>
      <h1>Consultants</h1>
      <table className={styles.table}>
        <thead>
          <tr>
            <th className={styles.th}>Name</th>
            <th className={styles.th}>Level</th>
          </tr>
        </thead>
        <tbody>{getConsultantTableRows(data.staff)}</tbody>
      </table>
    </>
  )
}
