import { FunctionComponent } from "react"
import { IfAuthenticated, IfNotAuthenticated } from "../../auth/Authenticated"

export const Homepage: FunctionComponent = () => {
  return (
    <div>
      <IfAuthenticated>
        <h1>Welcome back</h1>
      </IfAuthenticated>
      <IfNotAuthenticated>
        <h1>Welcome to HYPRx!</h1>
      </IfNotAuthenticated>
    </div>
  )
}
