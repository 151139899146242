import { ApolloClient, ApolloLink, ApolloProvider, from, HttpLink, InMemoryCache } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { FunctionComponent, ReactNode } from "react"
import { Config } from "../../../App/App"
import { getToken } from "../../../App/auth/useAuth"
import { Services } from "../types"

type Props = { children: ReactNode; serviceUris: Config }

const getServiceLinks = (serviceUris: Config) => {
  const clientServiceLink = new HttpLink({
    uri: serviceUris.clientService,
  })

  const staffServiceLink = new HttpLink({
    uri: serviceUris.staffService,
  })

  const userServiceLink = new HttpLink({
    uri: serviceUris.userService,
  })

  /* ApolloLink does not allow us to provide more than two links at a time. We can get around this
   * by, in the parent ApolloLink, providing another ApolloLink as the second link, which contains two links.
   */
  const remainingLinks = ApolloLink.split(
    (operation) => operation.getContext().client === Services.CLIENT,
    clientServiceLink,
    userServiceLink,
  )

  return ApolloLink.split(
    (operation) => operation.getContext().client === Services.STAFF,
    staffServiceLink,
    remainingLinks,
  )
}

export const AuthorisedApolloProvider: FunctionComponent<Props> = ({ serviceUris, children }) => {
  const authLink = setContext(async (_, { headers }) => {
    const token = await getToken()
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    }
  })

  const serviceLinks = getServiceLinks(serviceUris)

  const graphqlClient = new ApolloClient({
    link: from([authLink, serviceLinks]),
    cache: new InMemoryCache((window as any).__APOLLO_STATE__),
  })

  return <ApolloProvider client={graphqlClient}>{children}</ApolloProvider>
}
