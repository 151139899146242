import style from "./index.module.css"
import { AuthProvider } from "./auth/AuthProvider"
import { AuthorisedApolloProvider } from "../apis/graphql/AuthorisedApolloProvider"
import { BrowserRouter } from "react-router-dom"
import { Header } from "./Header/Header"
import { AppRoutes } from "./Routes/Routes"
import { useEffect, useState } from "react"

export type Config = {
  clientService: string
  staffService: string
  userService: string
}

export function App() {
  const [config, setConfig] = useState<Config>()

  useEffect(() => {
    async function getConfig() {
      const response = await fetch("config.json")
      const json = await response.json()
      // TODO: validate the json here
      setConfig(json)
    }

    getConfig().then()
  }, [])

  return (
    <AuthProvider>
      {config ? (
        <AuthorisedApolloProvider serviceUris={config}>
          <BrowserRouter>
            <div className={style.app}>
              <Header className={style.header} />
              <main>
                <AppRoutes />
              </main>
            </div>
          </BrowserRouter>
        </AuthorisedApolloProvider>
      ) : (
        <p>I'm working on it!</p>
      )}
    </AuthProvider>
  )
}
