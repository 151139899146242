import { gql } from "@apollo/client"
import { LevelsQuery } from "./useLevelData.generated"
import { useStaffQuery } from "../../../apis/graphql/staffServiceHooks"

export const LEVELS = gql`
  query Levels {
    levels {
      seniority
      rate
    }
  }
`

export const useLevelData = () => useStaffQuery<LevelsQuery>(LEVELS)
