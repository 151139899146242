import { FunctionComponent } from "react"
import { useLevelData } from "./useLevelData"
import { LevelsQuery } from "./useLevelData.generated"
import styles from "../../index.module.css"

type LevelList = LevelsQuery["levels"]

const getLevelTableRows = (levels: LevelList) => {
  const levelsCopy = [...levels]
  const sortedLevels = levelsCopy.sort((a: LevelList[0], b: LevelList[0]) => {
    if (a.rate < b.rate) {
      return 1
    }
    return -1
  })
  return sortedLevels.map((level) => (
    <tr key={level.seniority}>
      <td role="cell">{level.seniority}</td>
      <td role="cell">${level.rate}</td>
    </tr>
  ))
}

export const Levels: FunctionComponent = () => {
  const { loading, data, error } = useLevelData()

  if (loading) return <h2>Loading...</h2>
  if (error) return <h2>Rate card not found</h2>
  if (!data?.levels) return <h2>Rates do not exist</h2>
  return (
    <>
      <h1>Rate Card</h1>
      <table className={styles.table}>
        <thead>
          <tr>
            <th className={styles.th}>Name</th>
            <th className={styles.th}>Rate</th>
          </tr>
        </thead>
        <tbody>{getLevelTableRows(data.levels)}</tbody>
      </table>
    </>
  )
}
