import { gql } from "@apollo/client"
import { MarginReportStaffQuery } from "./useMarginReportData.generated"
import { useStaffQuery } from "../../../apis/graphql/staffServiceHooks"

export const MARGIN_REPORT_STAFF = gql`
  query MarginReportStaff {
    marginReport {
      rows {
        annualCost
        annualMargin
        annualRevenue
        billability
        name
        seniority
        staffMemberId
      }
      outlierScope {
        lowest
        low
        high
        highest
      }
    }
  }
`

export const useMarginReportData = () => useStaffQuery<MarginReportStaffQuery>(MARGIN_REPORT_STAFF)
