import { FunctionComponent } from "react"
import { IfAuthenticated, IfNotAuthenticated } from "../../auth/Authenticated"
import { useAuth } from "../../auth/useAuth"

export const LoginLogout: FunctionComponent = () => {
  const { login, logout } = useAuth()

  return (
    <>
      <IfAuthenticated>
        <button onClick={logout}>Logout</button>
      </IfAuthenticated>
      <IfNotAuthenticated>
        <button onClick={login}>Login</button>
      </IfNotAuthenticated>
    </>
  )
}
