import { gql } from "@apollo/client"
import { StaffQuery } from "./useStaffData.generated"
import { useStaffQuery } from "../../../apis/graphql/staffServiceHooks"

export const STAFF = gql`
  query Staff {
    staff {
      id
      name
      contract {
        level {
          id
          seniority
          rate
        }
      }
    }
  }
`

export const useStaffData = () => useStaffQuery<StaffQuery>(STAFF)
