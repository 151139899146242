import { DocumentNode } from "graphql"
import { OperationVariables, useMutation, useQuery } from "@apollo/client"
import { MutationOptions, QueryOptions, Services } from "./types"

export const useClientQuery = <T extends Record<string, any>>(query: DocumentNode, options?: QueryOptions) =>
  useQuery<T>(query, { ...options, context: { client: Services.CLIENT } })

export const useClientMutation = <T extends Record<string, any>, T2 extends OperationVariables>(
  query: DocumentNode,
  options?: MutationOptions<T, T2>,
) => useMutation<T, T2>(query, { ...options, context: { client: Services.CLIENT } })
