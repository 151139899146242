import { gql } from "@apollo/client"
import {
  UpdateClientHoursReportSpreadsheetMutation,
  UpdateClientHoursReportSpreadsheetMutationVariables,
} from "./useUpdateClientHoursReport.generated"
import { useClientMutation } from "../../../apis/graphql/clientServiceHooks"

export const UPDATE_CLIENT_HOURS_REPORT = gql`
  mutation UpdateClientHoursReportSpreadsheet($input: UpdateClientHoursReportSpreadsheetInput!) {
    updateClientHoursReportSpreadsheet(input: $input) {
      weekEnding
    }
  }
`

export const useUpdateClientHoursReportSpreadsheet = () =>
  useClientMutation<UpdateClientHoursReportSpreadsheetMutation, UpdateClientHoursReportSpreadsheetMutationVariables>(
    UPDATE_CLIENT_HOURS_REPORT,
  )
