import { MutationHookOptions, OperationVariables, QueryHookOptions } from "@apollo/client"

export enum Services {
  STAFF = "staff",
  CLIENT = "client",
  USER = "user",
}

export type QueryOptions = Omit<QueryHookOptions, "context">
export type MutationOptions<T extends Record<string, any>, T2 extends OperationVariables> = Omit<
  MutationHookOptions<T, T2>,
  "context"
>
