import { useStaffMemberData } from "./useStaffMemberData"
import { useParams } from "react-router-dom"
import { EditConsultantProfile } from "./EditConsultantProfile/EditConsultantProfile"
import { FunctionComponent } from "react"

export const ConsultantProfile: FunctionComponent = () => {
  const { consultantId } = useParams()
  const { loading, error, data } = useStaffMemberData(consultantId ?? "")

  if (loading) return <h2>Loading...</h2>
  if (error) return <h2>No consultant found</h2>
  if (!data?.staffMember) return <h2>Consultant does not exist</h2>

  return (
    <>
      <h2>{data.staffMember.name}</h2>
      <EditConsultantProfile profile={data.staffMember} />
    </>
  )
}
