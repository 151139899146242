import { FunctionComponent, useState } from "react"
import { SubmitHandler } from "react-hook-form"
import Form from "../../../../components/RequiredField/Form"
import { useUpdateProfile } from "./useUpdateProfile"
import { Input } from "../../../../components/RequiredField/Input"
import { UserQuery } from "../useProfileData.generated"

type UserEditFields = Pick<UserQuery["user"], "name" | "employmentType" | "email" | "phoneNumber">

interface Props {
  profile: UserQuery["user"]
}

export const EditProfile: FunctionComponent<Props> = ({ profile }) => {
  const [submitted, setSubmitted] = useState(false)
  const [updateUser] = useUpdateProfile()

  const onSubmit: SubmitHandler<UserEditFields> = async (formData) => {
    await updateUser({
      variables: {
        input: {
          id: profile.id,
          name: formData.name,
          phoneNumber: formData.phoneNumber,
          authSub: profile.authSub,
          email: profile.email,
        },
      },
    })
    setSubmitted(true)
  }

  return (
    /* "handleSubmit" will validate your inputs before invoking "onSubmit" */
    <Form onSubmit={onSubmit} defaultValues={profile}>
      {/* include validation with required or other standard HTML validation rules */}
      <Input label="Full Name" name="name" />
      <Input label="Primary Email" name="email" disabled />
      <Input
        label="Phone Number"
        name="phoneNumber"
        options={{
          pattern: {
            value: /^\+?[0-9+]{7,20}$/,
            message: "Phone number must contain only numbers, with optional leading +",
          },
        }}
      />
      <button>Save</button>
      {submitted && "Updated!"}
    </Form>
  )
}
