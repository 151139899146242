import { Route, Routes } from "react-router-dom"

import { Homepage } from "../main/Homepage/Homepage"
import { ProtectedRoute } from "../auth/ProtectedRoute"
import { Consultants } from "../main/Consultants/Consultants"
import { ConsultantProfile } from "../main/ConsultantProfile/ConsultantProfile"
import { Profile } from "../main/Profile/Profile"
import { Levels } from "../main/Levels/Levels"
import { MarginReport } from "../main/MarginReport/MarginReport"
import { ClientHoursReporting } from "../main/ClientHoursReporting/ClientHoursReporting"

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Homepage />} />
      <Route path="/consultants" element={<ProtectedRoute component={Consultants} />} />
      <Route path="/consultant-profile/:consultantId" element={<ProtectedRoute component={ConsultantProfile} />} />
      <Route path="/my-profile" element={<ProtectedRoute component={Profile} />} />
      <Route path="/ratecard" element={<ProtectedRoute component={Levels} />} />
      <Route path="/marginreport" element={<ProtectedRoute component={MarginReport} />} />
      <Route path="/clienthoursreporting" element={<ProtectedRoute component={ClientHoursReporting} />} />
    </Routes>
  )
}
