import { RegisterOptions, UseFormRegister } from "react-hook-form"

// Token from https://react-hook-form.com/advanced-usage#SmartFormComponent

export function Input({
  name,
  label,
  options,
  readOnly = false,
  register,
  disabled,
  ...rest
}: {
  name: string
  label: string
  readOnly?: boolean
  disabled?: boolean
  register?: UseFormRegister<any>
  options?: RegisterOptions
}) {
  return (
    <div>
      <label htmlFor={label}>{label}</label>
      <input id={label} {...(register && register(name, options))} {...rest} readOnly={readOnly} disabled={disabled} />
    </div>
  )
}
