// TODO find a library to do this work for us

export function capitalise(s: string): string {
  return s.charAt(0).toUpperCase() + s.slice(1)
}

// TODO this will overwrite key value pairs that exist at multiple levels
export function objectFlatten(obj: Record<string, any>, result: Record<string, any> = {}): Record<string, any> {
  for (let key in obj) {
    if (typeof obj[key] == "object") {
      objectFlatten(obj[key], result)
    } else {
      result[key] = obj[key]
    }
  }
  return result
}
