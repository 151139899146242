import React, { Children, createElement, FormHTMLAttributes } from "react"
import { DeepPartial, SubmitHandler, UnpackNestedValue, useForm } from "react-hook-form"

interface FormProps<T> extends Omit<FormHTMLAttributes<HTMLFormElement>, "onSubmit"> {
  children?: React.ReactNode
  onSubmit: SubmitHandler<T>
  defaultValues: UnpackNestedValue<DeepPartial<T>> | undefined
}

const isPrimitive = (node: React.ReactNode): node is string | boolean | number => {
  if (typeof node === "string") return true
  if (typeof node === "boolean") return true
  if (typeof node === "number") return true
  return false
}

// Token from here: https://react-hook-form.com/advanced-usage#SmartFormComponent
export default function Form<T>({ children, onSubmit, defaultValues }: FormProps<T>): JSX.Element {
  const methods = useForm<T>({ defaultValues })
  return (
    <form onSubmit={methods.handleSubmit(onSubmit)}>
      <>
        {Children.map(children, (child) => {
          if (isPrimitive(child)) return child
          if (!(child && "props" in child)) return child
          return child.props.name
            ? createElement<T>(child.type, {
                ...{
                  ...child.props,
                  register: methods.register,
                  key: child.props.name,
                },
              })
            : child
        })}
        {Object.entries(methods.formState.errors).map(([key, value]) => (
          <div key={key}>Cannot update: {value.message}</div>
        ))}
      </>
    </form>
  )
}
