import { gql } from "@apollo/client"
import { StaffMemberQuery } from "./useStaffMemberData.generated"
import { useStaffQuery } from "../../../apis/graphql/staffServiceHooks"

export const STAFF_MEMBER = gql`
  query StaffMember($id: String!) {
    staffMember(id: $id) {
      id
      name
      contract {
        level {
          id
          seniority
          rate
        }
      }
    }
  }
`

export const useStaffMemberData = (id: string) =>
  useStaffQuery<StaffMemberQuery>(STAFF_MEMBER, { variables: { id }, skip: !id })
