import { MarginReportStaffQuery } from "../useMarginReportData.generated"

export const getMarginCellColor = (
  margin: number,
  outlierScopes: MarginReportStaffQuery["marginReport"]["outlierScope"],
) => {
  const marginAsDecimal = margin / 100
  if (marginAsDecimal < outlierScopes.lowest || marginAsDecimal > outlierScopes.highest) {
    return "red"
  }
  if (marginAsDecimal < outlierScopes.low || marginAsDecimal > outlierScopes.high) {
    return "yellow"
  }
}
