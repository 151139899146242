import { FunctionComponent } from "react"
import { Link } from "react-router-dom"
import styles from "./index.module.css"
import { LoginLogout } from "./LoginLogout/LoginLogout"
import { IfAuthenticated } from "../auth/Authenticated"
import { useLoggedInUserData } from "./useLoggedInUserData"

export interface HeaderProps {
  className?: string
}

export const Header: FunctionComponent<HeaderProps> = ({ className }) => {
  // TODO: this hook triggers creating a user if it doesn't exist - move this to it's own mutation and then to the login button
  // eventually we want to have the login routed through Hypr api so we can auto generate the profile
  useLoggedInUserData()

  return (
    <header className={[className, styles.navList].join(" ")}>
      <LoginLogout />
      <Link to="/" className={styles.link}>
        Home
      </Link>

      <IfAuthenticated>
        <Link to="/my-profile" className={styles.link}>
          My Profile
        </Link>
        <Link to="/consultants" className={styles.link}>
          Consultants
        </Link>
        <Link to="/ratecard" className={styles.link}>
          Rate Card
        </Link>
        <Link to="/marginreport" className={styles.link}>
          Margin Report
        </Link>
        <Link to="/clienthoursreporting" className={styles.link}>
          Client Hours Reporting
        </Link>
      </IfAuthenticated>
    </header>
  )
}
