/// <reference types="react-scripts" />

import "./index.css"
import { App } from "./App/App"
import { StrictMode } from "react"
import { createRoot } from "react-dom/client"
import { reportWebVitals } from "./apis/webVitals/reportWebVitals"

const root = createRoot(document.getElementById("root")!)

root.render(
  <StrictMode>
    <App />
  </StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
export { Services } from "./apis/graphql/types"
