import { gql } from "@apollo/client"
import { LoggedInUserQuery } from "./useLoggedInUserData.generated"
import { useUserQuery } from "../../apis/graphql/userServiceHooks"

// todo change this query to a create user mutation
export const USER = gql`
  query loggedInUser {
    loggedInUser {
      id
      authSub
    }
  }
`

export const useLoggedInUserData = () => useUserQuery<LoggedInUserQuery>(USER)
