import { FunctionComponent } from "react"
import { EditProfile } from "./EditProfile/EditProfile"
import { useProfileData } from "./useProfileData"

export const Profile: FunctionComponent = () => {
  const { loading, error, data } = useProfileData()

  if (loading) return <h2>Loading...</h2>
  if (error) return <h2>No profile found</h2>
  if (!data?.user) return <h2>Profile does not exist</h2>
  return (
    <>
      <h1>My Profile</h1>
      <>
        <h3>{data?.user.name}</h3>
        <EditProfile profile={data.user} />
      </>
    </>
  )
}
