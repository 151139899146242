import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react"
import createAuth0Client from "@auth0/auth0-spa-js"
import { FC, ComponentType } from "react"

/*
 * This file acts as isolation layer for Auth0. It is the only file in the codebase that should
 * take a direct dependency on Auth0. There are a couple of benefits to this approach. 1) we can
 * clearly see what parts of Auth0 we're using and how. 2) We can mock these functions in our
 * tests instead of the Auth0 functions directly, which can become brittle considering future
 * changes to Auth0 out of our control. 3) The isolation minimises the impact of future Auth0
 * changes to a single location in our codebase.
 */

interface UseAuth {
  isAuthenticated: boolean
  login: () => Promise<void>
  logout: () => void
  getAccessTokenSilently: () => Promise<string>
}

export function useAuth(): UseAuth {
  const { loginWithRedirect, logout, isAuthenticated, getAccessTokenSilently } = useAuth0()

  return {
    isAuthenticated,
    login: () => loginWithRedirect(),
    logout: () => logout({ returnTo: window.location.origin }),
    getAccessTokenSilently,
  }
}

export interface WithAuthRequiredOptions {
  onRedirecting?: () => JSX.Element
  // add more members of WithAuthenticationRequiredOptions as needed
}

export const withAuthRequired = <P extends object>(
  component: ComponentType<P>,
  options?: WithAuthRequiredOptions,
): FC<P> => withAuthenticationRequired(component, { onRedirecting: options?.onRedirecting })

// non-hook method of getting token
export const getToken = async () => {
  const auth0 = await createAuth0Client({
    domain: "hyprnz.au.auth0.com",
    client_id: "YO5drULeDSRpzjpMvnnrzd5A1bEMb9Ah",
    redirect_uri: window.location.origin,
  })
  const token = await auth0.getTokenSilently({ audience: "https://hyprnz/api/v1" })
  return token
}
