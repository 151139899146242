import { Auth0Provider } from "@auth0/auth0-react"
import { FunctionComponent, ReactNode } from "react"

type Props = { children: ReactNode }

export const AuthProvider: FunctionComponent<Props> = ({ children }) => {
  return (
    <Auth0Provider
      domain="hyprnz.au.auth0.com"
      clientId="YO5drULeDSRpzjpMvnnrzd5A1bEMb9Ah"
      redirectUri={window.location.origin}
      audience="https://hyprnz/api/v1"
    >
      {children}
    </Auth0Provider>
  )
}
