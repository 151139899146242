import { gql } from "@apollo/client"
import { UpdateUserMutation, UpdateUserMutationVariables } from "./useUpdateProfile.generated"
import { useUserMutation } from "../../../../apis/graphql/userServiceHooks"

export const UPDATE_USER_PROFILE = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      name
      phoneNumber
    }
  }
`

export const useUpdateProfile = () =>
  useUserMutation<UpdateUserMutation, UpdateUserMutationVariables>(UPDATE_USER_PROFILE)
