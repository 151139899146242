import { StaffMemberQuery } from "../useStaffMemberData.generated"
import { capitalise, objectFlatten } from "../../../../helpers"
import { FunctionComponent } from "react"

// TODO: discuss benefits of this approach vs defining the JSX with specific values (i.e. name)
export const makeProfileItems = (profile: StaffMemberQuery["staffMember"]) => {
  const flatProfile = objectFlatten(profile)
  const keys = Object.keys(flatProfile)
  const wantKeys = keys.filter((key) => key !== "__typename" && key !== "id")
  return wantKeys.map((key) => (
    <div key={key}>
      <h3>{capitalise(key)}:</h3>
      <p>{flatProfile[key]}</p>
    </div>
  ))
}

interface Props {
  profile: StaffMemberQuery["staffMember"]
}

// TODO currently this just displays the user data
export const EditConsultantProfile: FunctionComponent<Props> = ({ profile }) => {
  return <>{makeProfileItems(profile)}</>
}
