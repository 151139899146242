import { gql } from "@apollo/client"
import { UserQuery } from "./useProfileData.generated"
import { useUserQuery } from "../../../apis/graphql/userServiceHooks"

export const GETPROFILE = gql`
  query user {
    user {
      id
      name
      employmentType
      email
      phoneNumber
      authSub
    }
  }
`

export const useProfileData = () => useUserQuery<UserQuery>(GETPROFILE)
