import { ComponentType, FC } from "react"
import { withAuthRequired } from "./useAuth"

interface Props {
  component: ComponentType
}

export const ProtectedRoute: FC<Props> = ({ component }) => {
  return withAuthRequired(component, {
    onRedirecting: () => (
      <>
        <h1>Redirecting to login</h1>
      </>
    ),
  })({}) // intentionally doesn't support props - only reason is we don't need them yet
}
