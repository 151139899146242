import { FormEvent, FormEventHandler, FunctionComponent, useState } from "react"
import DatePicker from "react-datepicker"
import { useUpdateClientHoursReportSpreadsheet } from "./useUpdateClientHoursReport"
import "react-datepicker/dist/react-datepicker.css" // needed to style/format calendar view of date picker – will change when we decide how to tackle styling generally

export const offsetDate = (date: Date): Date => {
  const weekday = date.getUTCDay()

  const daysToAdd = weekday === 0 ? 1 : 8 - weekday

  const clonedDate = new Date(date.getTime())

  // Add days to account for UT time difference, and because backend expects to calculate week based on a date provided from the following week
  clonedDate.setUTCDate(date.getUTCDate() + daysToAdd)

  // returned date will always be the Tuesday following the provided date
  return clonedDate
}

export const ClientHoursReporting: FunctionComponent = () => {
  const [submitted, setSubmitted] = useState(false)
  const [date, setDate] = useState(new Date())
  const [updateClientHoursReportSpreadsheet, { loading, error }] = useUpdateClientHoursReportSpreadsheet()

  const handleChange = (selectedDate: Date) => {
    setDate(selectedDate)
  }

  const handleSubmit: FormEventHandler = async (e: FormEvent) => {
    e.preventDefault()
    const convertedDate = offsetDate(date)
    try {
      await updateClientHoursReportSpreadsheet({
        variables: {
          input: {
            weekEnding: convertedDate.toString(),
          },
        },
      })
    } catch (error) {
      console.error("Failed to run graphql mutation")
    }
    setSubmitted(true)
  }

  if (loading) return <h2>Updating report...</h2>
  if (error) return <h2>Could not update report</h2>
  return (
    <>
      <h1>Client Hours Reporting</h1>
      <p>Update the client hours report spreadsheet for any given week:</p>
      <form onSubmit={handleSubmit}>
        <DatePicker selected={date} onChange={handleChange} calendarStartDay={1} maxDate={new Date()} />
        <button>Run report</button>
        {submitted && "Success"}
      </form>
    </>
  )
}
