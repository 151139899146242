import { ReactNode } from "react"
import { useAuth } from "./useAuth"

type IfAuthenticatedProps = {
  children: ReactNode
}

export const IfAuthenticated = ({ children }: IfAuthenticatedProps) => {
  const { isAuthenticated } = useAuth()
  return isAuthenticated ? <>{children}</> : null
}

export const IfNotAuthenticated = ({ children }: IfAuthenticatedProps) => {
  const { isAuthenticated } = useAuth()
  return !isAuthenticated ? <>{children}</> : null
}
